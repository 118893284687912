/* Context Menu */

.context-menu-icon {
  &:before {
    color: $body-color;
    font: normal normal normal 15px/1 "themify";
  }
  &.context-menu-icon-cut {
    &:before {
      content: '\e613';
    }
  }
  &.context-menu-icon-edit {
    &:before {
      content: '\e61c';
    }
  }
  &.context-menu-icon-copy {
    &:before {
      content: '\e6a3';
    }
  }
  &.context-menu-icon-paste {
    &:before {
      content: '\e6c8';
    }
  }
  &.context-menu-icon-delete {
    &:before {
      content: '\e605';
    }
  }
  &.context-menu-icon-quit {
    &:before {
      content: '\e646';
    }
  }
}
.context-menu-list {
  background: $card-bg;
  box-shadow: none;
  border: 1px solid $border-color;
  .context-menu-item {
    background: $card-bg;
    span {
      color: $body-color;
      font-size: .75rem;
      font-weight: 600;
    }
    &.context-menu-hover {
      background: rgba($white, .3);
      span {
        color: $white;
      }
    }
  }
  .context-menu-separator {
    border-bottom: 1px solid $border-color;
  }
}

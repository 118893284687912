/* Tabs */

.add-items {
  margin-bottom: 1.5rem;
  overflow: hidden;
  input[type="text"] {
    width: 100%;
    background: transparent;
    border: 0;
    padding-left: 0;
    @include placeholder {
      font-size: 1rem;
      color: #9b9b9b;
    }
  }
  .btn {
    margin-left: .5rem;
    i {
      font-size: 2.25rem;
    }
    .rtl & {
      margin-left: auto;
      margin-right: .5rem;
    }
  }
}

.list-wrapper {
  height: 100%;
  max-height: 100%;
  ul {
    padding: 0;
    text-align: left;
    list-style: none;
    margin-bottom: 0;
    li {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-start;
      font-size: .9375rem;
      padding: 1.063rem 1.4rem;
      border: 1px solid $border-color;
      margin-bottom: 0.812rem;
      border-radius: 7px;
      .form-check{
        @extend %ellipsor;
        max-width: 90%;
        margin-top: .25rem;
        margin-bottom: .25rem;
        .form-check-label{
          @extend%ellipsor;
        }
      }
    }
  }

  input[type="checkbox"] {
    margin-right: 15px;
  }

  .remove {
    @extend .ml-auto;
    color: $body-color;
    .rtl & {
      @extend .ml-0;
      @extend .mr-auto;
    }
    cursor: pointer;
    font-size: .7rem;
    font-weight: 600;
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    text-align: center;
  }

  .completed {
    .form-check {
      text-decoration: line-through;
      text-decoration-color: theme-color(info);
    }  
    .remove {
      @extend .text-primary;
    }
  }
}
